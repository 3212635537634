.pageWrapper {
  height: 100%;
}

.contentWrapper {
  height: 100%;
  display: flex;
}

.contentAreaWithTitleBar {
  width: calc(100% - 200px);
  height: 100%;
}
