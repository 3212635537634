.videoBox {
  margin: 0 auto;
  width: 300px;
  padding: 20px;
}
.video30 {
  width: 50%;
  height:calc(100% - 2 * 10px);
  padding: 10px;
}
.video70 {
  width: 50%;
  padding: 8px;
  height: calc(100% - 16px);
}
.videoCard {
  min-height: 100px;
  height: 180px;
  border-radius: 15px;
  border: 1px solid #dbdde1;
  display: flex;
  background-color: white;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
}
.formName {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
}

.dragTitle {
  font-size: 8px;
  color: #000;
  font-weight: 300;
  text-transform:capitalize;

}
.dltIcon{
  z-index:4;
  width: 15px !important;
}
.dragPlusTitle {
  font-size: 11px;
  color: #fff;
  margin-left: 4px;
  font-weight: 500;
}
.dragTitleDiv {
  background-color: #e6e6e6;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  display:flex;
  justify-content: space-between;
  align-items:center;
  z-index:2;
}
.dragPlusDiv {
  margin-top: 10px;
  height: 25px;
  display: flex;
  border-radius: 15px;
  border: 0.4px solid #0d2762;
  background-color: #0d2762;
}
.roundPlus {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
