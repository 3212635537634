.navBarWrapper {
  width: 200px;
  height: 100%;
  background-color: #7449f0;
  /* border-right: 0.1px solid #4c6fc4; */
}
.navbar {
  height: calc(100% - 79px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.navBarItem:hover {
  background-color: #4c6fc4;
  color: #ffffff;
}

.navBarItemActive {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
  background-color: #ffffff;
  color: #3d599b;
}
.logoDiv {
  text-align: center;
  padding: 10px;
}
.navBarItem,
.navBarItemLogout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  padding-left: 15%;
  cursor: pointer;
}
.logoutDiv {
  text-align: center;
  padding: 10px;
  width: 100px;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
}
.navItemName {
  font-size: 13px;
  font-weight: 500;
  margin-left: 20%;
}
.selectedTab {
  background-color: #fff;
  color: #fff;
}
