.pageWrapper {
  height: 100%;
}

.contentWrapper {
  height: 100%;
  display: flex;
}

.contentAreaWithTitleBar {
  width: calc(100% - 200px);
  height: 100%;
}
.contentArea {
  padding: 15px;
  overflow: auto;
  height: calc(100% - 95px);
}

.formCard {
  min-height: 250px;
  max-height: 250px;
  height: calc(100% - 28px);
  border-radius: 5px;
  border: 1.4px solid #7a7a7a;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
