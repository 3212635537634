.titleBarLayout {
  background-color: #fff;
  height: 65px;
  padding: 15px;
  border-bottom: 1px solid #cccccc;
}
.searchField {
  height: 35px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: #f1f3f4;
  border-radius: 4px;
}
.userName {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* Review styles from here */
.appHeading {
  width: 200px;
}
.logo {
  margin-top: 0px;
  margin-left: 15px;
}
.flexChildCenter {
  align-self: center;
}
.logoWrapper {
  display: flex;
  flex: 50%;
}
.profileWrapper {
  margin-left: 50px;
  justify-content: space-between;
  display: flex;
  flex: 50%;
}
.profileLogo {
  margin-right: 10px;
}
.profileTextWrapper {
  margin-right: 0px;
}
.profileTextSub {
  font-size: 11px;
  color: rgb(224, 224, 224);
}
.shopListPopup {
  color: #72777a;
  font-size: 16px;
  background-color: white;
  position: absolute;
  right: 24px;
  top: 75px;
  z-index: 100;
}
.shopListItem {
  padding: 15px 30px;
}
.actionButtons {
  justify-content: center;
  align-self: center;
  margin-right: 10px;
}
.actionBtnIcon {
  font-size: 14px !important;
}
.actionBtn {
  font-size: 11px !important;
  font-weight: 500 !important;
}
.Shop {
  font-size: 12px;
  margin-left: 8px;
  color: grey;
}
.profileSec {
  padding: 15px;
  cursor: pointer;
}

.backBtn {
  cursor: pointer;
  margin-right: 20px;
  font-size: 30px;
}
.controlsContainer {
  display: flex;
}
