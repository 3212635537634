.fileContainer {
  flex-wrap: nowrap !important;
}
.videoContainer {
  position: relative;
  height: 200px;
  width: 150px;
  margin-bottom: 10px;
}
.imageContainer {
  position: relative;
  height: 200px;
  width: 150px;
}
.uploadBtnContainer {
  text-align: center;
}
.uploadButton {
  display: grid;
  place-items: center;
  margin-left: 20px;
}

/* Add video section  */
.dialogContainer {
  padding: 20px;
  border-radius: 15px;
  position: relative;
}
.closeBtn {
  position: absolute;
  top: 20px;
  right: 35px;
  cursor: pointer;
}
.mainTitle {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  text-align: center;
}
.uploadsContainer {
  margin: 20px 0 20px;
  display: flex;
  justify-content: center;
}
.uploadContainer {
  position: relative;
}
.uploadTitle {
  text-align: center;
}
.uploadSection {
  margin-top: 10px;
  height: 150px;
  width: 180px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.uploadBtn {
  position: absolute;
  bottom: -25px;
  right: -25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #f1f3ff;
  cursor: pointer;
}
.uploadIcon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

/* Add form section */

.typeIcon {
  margin-right: 20px;
  color: gray;
  font-size: 14px !important;
}

.fieldPropsContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectInput {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-size: 18px;
  flex: 0.6;
}
.addFieldTitle {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
}
.fieldIcons {
  padding: 10px;
  border-radius: 5px;
  font-size: 15px !important;
}
.requiredIcon {
  font-size: 15px !important;
  color: gray;
}
.addFieldIcon {
  font-size: 24px !important;
  color: green;
}
.disabledAddField {
  font-size: 24px !important;
  color: gray;
}
.requiredField {
  color: red;
}
