.rootView {
  height: 100%;
}
.oops {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
}
.nointernet {
  font-size: 16px;
  font-weight: bold;
  padding-top: 20px;
  color: #fff;
}
.description {
  font-size: 12px;
  color: #fff;
}
.buttonDiv {
  padding-top: 50px;
}
.tryBtn {
  background-image: linear-gradient(to bottom right, #5f2de8, #e93953);
  border-radius: 20px;
  width: 150px;
  color: #fff;
  font-weight: 600;
}
