h3 {
  margin: 0;
}

.contentWrapper {
  background-color: #f1f3f4;
  overflow: hidden;
  height: 100%;
  display: flex;
}

.pageWrapper {
  height: 100%;
}

.container {
  padding: 15px;
  height: calc(100% - 95px);
  background-color: #f1f3f4;
  overflow: overlay;
}

.contentAreaWithTitleBar {
  width: calc(100% - 200px);
  height: 100%;
}

.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background-image: linear-gradient(to bottom right, #5f2de8, #e93953);
  flex-direction: column;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20px;
}
.outerBoxFrame {
  background-color: #fff;
  width: 100%;
  display: flex;
  border-radius: 6px;
  max-width: 800px;
}

.formBoxFrame {
  padding: 40px;
}
.titleText {
  margin: 20px;
  color: #404040;
  text-align: center;
}
.textCenter {
  margin-top: 20px;
  text-align: center;
}
.signupQuote {
  font-size: 13px;
  font-weight: 600;
  margin-left: 20px;
  color: #5f6368;
}
.loginButton {
  text-align: center;
  background-color: #7449f0;
}
.signupHintWrapper {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}
.bannerDiv {
  border-radius: 6px 0 0 6px !important;
  width: 100%;
  height: 100%;
}
/* Review below styles */

/* createOrganization page */

.fieldInput {
  margin: 25px 0;
}

.errorMsg {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 12px;
}
