.pageWrapper {
  height: 100%;
}

.contentWrapper {
  height: 100%;
  display: flex;
}

.contentArea {
  padding: 15px;
  width: calc(100% - 100px);
}

.formCard {
  min-height: 250px;
  max-height: 250px;
  height: calc(100% - 28px);
  border-radius: 5px;
  border: 1.4px solid #7a7a7a;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.formName {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.videoDiv {
  height: 220px;
  border-radius: 4px;
  overflow: hidden;
}
.videoNameDiv {
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  padding-left: 4px;
  padding-right: 4px;
  color: #74777b;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.formUrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textFeild {
  width: 500px;
}
