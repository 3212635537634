.formTileWrapper {
  margin: 0 auto;
  width: 350px;
}
.formTileContainer {
  min-height: 170px;
  height: 320px;
  border-radius: 15px;
  border: 0.2px solid #dbdde1;
  display: flex;
  background-color: white;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
}
.fieldContainer {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.fieldName {
  font-size: 16px;
  color: gray;
  font-weight: 500;
}
.fieldPropsContainer {
  display: flex;
}

.fieldPropsIcon {
  font-size: 16px;
  color: gray;
}
.icon {
  font-size: 18px !important;
}

.requiredIcon {
  font-size: 16px !important;
  font-weight: bold;
  color: red;
}

.formTileName {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  color: black;
}
