.formUrl {
    display: flex;
    align-items: center;
    padding: 5px;
}

.actionButton {
    padding: 8px;
    cursor: pointer;
}

.backdrop {
    background-color: #0005;
}

.subTitle {
    font-size: 17px;
    margin: 8px 0;
}
