.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  background-image: linear-gradient(to bottom right, #5f2de8, #e93953);
  flex-direction: column;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20px;
}

.outerBoxFrame {
  background-color: #fff;
  width: 100%;
  display: flex;
  border-radius: 6px;
  max-width: 800px;
}

.formBoxFrame {
  padding: 40px;
}

.loginForm {
  margin-top: 35px;
}

.titleText {
  margin: 20px;
  color: #404040;
  text-align: center;
}

.textCenter {
  margin-top: 8px;
  text-align: center;
}
.errorMsg {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 12px;
}

.forgotPasswordWrapper {
  font-size: 14px;
  margin-top: 14px;
  text-align: right;
}

.forgotPassword {
  text-decoration-color: transparent;
  color: #434a53;
}

.socialSignInWrapper {
  margin-top: 15px;
}
.socialMediaLogin {
  margin: 8px;
}

.signupHintWrapper {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}

/* Review below styles */
.LoginLabel {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 600;
}
