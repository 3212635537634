.pageWrapper {
  height: 100%;
}

.contentWrapper {
  height: 100%;
  display: flex;
}

.contentAreaWithTitleBar {
  width: calc(100% - 200px);
  height: 100%;
}
.contentArea {
  padding: 10px;
  overflow: auto;
  height: calc(100% - 85px);
  background-color: #f1f3f4;
}
.pageTitle {
  font-size: 20px;
  margin: 10px 0 !important;
}
.formCard {
  min-height: 250px;
  max-height: 250px;
  height: calc(100% - 28px);
  border-radius: 5px;
  border: 1.4px solid #7a7a7a;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
.pageTitleSection {
  display: flex;
  justify-content: space-between;
}

.selectBox {
  margin: 0 0 5px !important;
  min-width: 200px !important;
}

/* form details page */
.backSection {
  display: flex;
  font-size: 16px;
}

.tabTitle {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
}
.backIconSection {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.backIcon {
  color: rgb(0, 0, 0);
  font-size: 1.8rem;
}
.detailsSection {
  padding: 15px;
  /* height: calc(100% / 3 - 15px); */
  /* overflow-y: scroll; */
  margin: 15px 0;
}
.detailSectionTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.listSection {
  padding: 10px 15px !important;
}
.listItem {
  padding: 10px 0 !important;
}
.responseText {
  font-size: 15px !important;
  font-weight: 400 !important;
}
.question {
  font-size: 13px !important;
  width: 40%;
}

.answer {
  color: black !important;
}
.answerPart {
  margin-left: 35px;
}
.loadPosition {
  position: absolute;
  top: 25%;
  left: 50%;
}
