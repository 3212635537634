.container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.loaderContainer {
  position: relative;
  /* padding: 20px; */
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 100px;
  font-size: 11px;
  line-height: 12px;
}
.loaderContainer::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  transform: scale(1.04);
  width: 100%;
  height: 100%;
  background: transparent;
  border-right: 3px solid #6c05f2;
  box-shadow: 0px 0px 20px 10px rgb(0 0 0 / 10%);
  animation: spin 1s linear infinite;
}
.companyName {
  font-weight: 500;
  font-size: 13px;
}
.createdBrand {
  margin-top: 5px;
  font-weight: 750;
  font-size: 15px;
}

@keyframes spin {
  from {
    transform: scale(1.04) rotate(0deg);
  }
  to {
    transform: scale(1.04) rotate(360deg);
  }
}
